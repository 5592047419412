import React from "react";
import styles from "./Club.module.scss"
import { Card_members } from "./card_members/Card_members";
import cyril from "../../assets/cyril.png";
import yann from "../../assets/yann.png";
import jc from "../../assets/jc.png";
import michel from "../../assets/michel.png";
import Footer from '../../shared/footer/Footer';
import Header from '../../shared/header/Header';

const clubText = [
  {title: "L'abuzif poker club"},
  {paragraph:"Club fondé en 2016."},
  {paragraph:"Voici notre histoire."},
  {paragraph:"A l'origine créé par un groupe d'amis qui souhaitaient se réunir dans un moment de partage autour d'une table de poker. Le club a depuis pris de l'envergure et accueille aujourd'hui une quarantaine de membres."},
  {paragraph:"L'idée originelle est toujours là. Se retrouver tous les Jeudis soirs pour jouer au poker dans une ambiance détendue, cordiale et respectueuse de chacun. Prônant un poker associatif, nous avons plaisir à faire découvrir toutes les facettes de ce jeu avec des joueurs de tous niveaux. Nous organisons également des évènements ouverts à tous, ainsi que 2 tournois annuels."},
  {paragraph:"Bienvenue dans notre club de poker, où la bonne humeur et la convivialité se retrouvent autour de la table de jeu."},
  {paragraph:"Bien à vous,"},
  {paragraph:"Le Bureau de L'Abûzif Poker Club"},
]

const staff = [
  {name: "Cyril Davoust", role: "Président", expression: "Au poker, l'important n'est pas les cartes que j'ai en main mais celles que mon adversaire pense que j'ai.", picture: cyril},
  {name: "Yann Harache", role: "Vice-Président", expression: "Bande de chatards.", picture: yann},
  {name: "Jean-charles Defrance", role: "Trésorier", expression: "Le poker, une minute pour connaître les règles mais une vie pour les maîtriser.", picture: jc},
  {name: "Michel Lebriere", role: "Secrétaire", expression: "A l'Abûzif c'est cool, c'est joyeux on ira tous au day 2.", picture: michel},
]

function Club() {
  return (
    <>
    <Header />
      <section className={styles.main}>
        <div className={styles.container}>
          <section className={styles.club}>
            {clubText.map((t, index) => (
              <>
              <h2 className={styles.title}>{t.title}</h2>
              <p key={index} className={styles.paragraph}>{t.paragraph}</p>
              </>
            ))}
          </section>
          <section className={styles.staff}>
            <h2 className={styles.title}>Le bureau</h2>
            <div className={styles.containerStaff}>
            {staff.map((s) => (
                <Card_members key={s.name} {...s} />
            ))} 
            </div> 
          </section>
        </div>  
      </section>
    <Footer />
    </>
  );
}

export default Club;


