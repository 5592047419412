import styles from "./Ranking.module.scss"
import { FormThursdayComponent } from "./form_thursday/component"; 
import { ClassementComponent } from "./classement/component";  
import Footer from '../../shared/footer/Footer';
import Header from '../../shared/header/Header'; 

function Ranking() {
  return (
    <>
    <Header />
    <section className={styles.main}>
      <div className={styles.container}>
        <FormThursdayComponent manche={1} day={1} />
        {/* <ClassementComponent /> */}
      </div>
    </section>
    <Footer />
    </>
  );
}

export default Ranking;



