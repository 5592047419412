import * as React from "react";
import styles from "./component.module.scss"
import logo from '../../../assets/logoClub.webp';
import { useForm } from "react-hook-form";
import axios from 'axios';
import no_minor from "../../../assets/no-minor.png";

function Home() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = (data: any) => formAction(data)

  console.log(watch("firstName"))

    return ( 
      <section className={styles.main}>
        <div className={styles.container}>
          <div className={styles.affiche}>
            <p><img src={logo} alt="logo" className={styles.rotating}/></p>
            <h2 className={styles.title}><span className={styles.red}>POKER </span>TOUR</h2>
            <p className={styles.date}>Samedi 02 Novembre</p>
            <p className={styles.open}>Début du tournoi à 10h00</p>
            <p className={styles.open}>Entrée tardive 1H</p>
            <p className={styles.open}>Ouverture des portes 9h00</p>
            <p className={styles.details}>Deep stack, Hold&apos;em No limit</p>
            <p className={styles.details}><span className={styles.red}>50K</span> jetons et Round de <span className={styles.red}>30</span> minutes</p>
            <p className={styles.details}>Tournoi FREEROLL</p>
            <p className={styles.separator}></p>
            <p className={styles.details}>Limité à 120 joueurs</p>
            <p className={styles.separator}></p>
            <p className={styles.details}>Restauration & Buvette sur place</p>
            <p className={styles.separator}></p>
            <address>
              <p>Salle des fêtes, 2 rue des fossées, 28410 Bû</p>
            </address>
            <p className={styles.details}>Pour toutes questions, Cyril au 06.35.34.59.70</p>
            <p className={styles.details}>Inscription via notre formulaire sur https://labuzifpokerclub.fr</p>
            <p className={styles.open}><span className={styles.red}>Inscription Obligatoire</span></p>
            <p className={styles.open}><img src={no_minor} alt="logo no minor" className={styles.noMinor}/></p>
          </div>
          <div className={styles.registrer}>
            <form className={styles.formContact} onSubmit={handleSubmit(onSubmit)}>
              <h3 className={styles.title}>Formulaire d'inscription</h3>
              <div className={styles.formItem}>
                  <label htmlFor="lastName">Nom <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="Nom" id="lastName" {...register("lastName", {required: true})}/></p>
                  {errors.lastName && <span className={styles.red}>Veuillez saisir votre nom !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="firstName">Prénom <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="Prénom" id="firstName" {...register("firstName", {required: true})}/></p>
                  {errors.lastName && <span className={styles.red}>Veuillez saisir votre prénom !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="pseudo">Pseudo <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="Pseudo" id="pseudo" {...register("pseudo", {required: true})}/></p>
                  {errors.lastName && <span className={styles.red}>Veuillez saisir un pseudo !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="email">Adresse mail <span className={styles.red}>*</span></label>
                  <p><input type="email" placeholder="Adresse mail" id="email" {...register("email", {required: true})}/></p>
                  {errors.lastName && <span className={styles.red}>Veuillez saisir une adresse mail valide !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="address">N° et voie <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="ex: 30, rue grande" id="address" {...register("street", {required: true})}/></p>
                  {errors.address && <span className={styles.red}>Veuillez saisir une adresse postal valide !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="cp">Code postal <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="ex: 28260" id="cp" {...register("cp", {required: true})}/></p>
                  {errors.address && <span className={styles.red}>Veuillez saisir un code postal valide !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="city">Ville <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="anet" id="city" {...register("city", {required: true})}/></p>
                  {errors.address && <span className={styles.red}>Veuillez saisir une ville valide !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="phone">Tél <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="06........" id="phone" {...register("phone", {required: true})}/></p>
                  {errors.address && <span className={styles.red}>Veuillez saisir un numéro de téléphone valide !</span>}
              </div>
                <div className={styles.formItem}>
                  <label htmlFor="club">Votre club (optionnel)</label>
                  <p><input type="text" placeholder="Votre club" id="club" {...register("club")}/></p>
                </div>
                <div className={styles.formItemInline}>
                  <input type="checkbox" id="age" {...register("age")} onClick={isChecked}/>
                  <label htmlFor="age">Je certifie avoir plus de 18 ans</label>
                </div>
                <div className={styles.formItem}>
                  <p className={styles.blockSubmit}>
                  <input type="submit" name="sendMessage" value="Inscription" id="sendMessage" className={styles.button62} disabled/>
                  </p>
                </div>
            </form> 
          </div> 
        </div>
      </section>
  );
}

export default Home;

function isChecked() {
  let ageElement = document.getElementById('age') as HTMLInputElement;
  let button = document.getElementById('sendMessage') as HTMLInputElement;
 
    if ( ageElement && ageElement.checked) {
    button.disabled = false;
    }
   
  
}

async function formAction(data: { pseudo: string; firstName: string; lastName: string; email: string; street: string; cp: string; city: string; club: any; phone: string;}) {
  if (data.cp.length > 5) {
    alert("Veuillez saisir un code postal valide");
    return;
  }
  axios.post('https://labuzifpokerclub.fr/api/email.php', {
    form: 'Inscription Poker Tour',
    firstName: data.firstName,
    lastName: data.lastName,
    pseudo: data.pseudo,
    email: data.email,
    street: data.street,
    cp: data.cp,
    city: data.city,
    phone: data.phone,
    club: data.club
  })
  .then( (response) => {
    console.log(response);
      cancelForm();
    alert("Votre demande d'inscription a bien été prise en compte " + JSON.stringify(data.pseudo) + " \n Nous vous remercions pour votre inscription et vous souhaitons bonne chance pour le tournoi. \n Nous vous attendons le 25 Mai à partir de 9h. \n Cordialement.");
  })
  .catch(function (error) {
    cancelForm();
    console.log(error);
  });
}

function cancelForm() {
  let firstNameId = document.getElementById('firstName') as HTMLInputElement;
  firstNameId.value = '';
  let lastNameId = document.getElementById('lastName') as HTMLInputElement;
  lastNameId.value = '';
  let pseudoId = document.getElementById('pseudo') as HTMLInputElement;
  pseudoId.value = '';
  let emailId = document.getElementById('email') as HTMLInputElement;
  emailId.value = '';
  let addressId = document.getElementById('address') as HTMLInputElement;
  addressId.value = '';
  let phoneId = document.getElementById('phone') as HTMLInputElement;
  phoneId.value = '';
  let clubId = document.getElementById('club') as HTMLInputElement;
  clubId.value = '';
  let cpId = document.getElementById('cp') as HTMLInputElement;
  cpId.value = '';
  let cityId = document.getElementById('city') as HTMLInputElement;
  cityId.value = '';
  let ageId = document.getElementById('age') as HTMLInputElement;
  ageId.checked = false;
}
